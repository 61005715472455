<template>
  <v-container class="pa-0 mt-6 first-footer-part" :fluid="isSafari">
    <v-row class="flex-row mt-13">
      <v-col cols="12" md="3" class="d-flex flex-column align-center text-center pl-md-0">
        <v-img
          width="100px"
          max-height="30px"
          class="mb-4"
          :src="IconFooterContainer"
          :alt="t('suitable_container')" />
        <h4>{{ props.footer.container_step_label }}</h4>
        <span class="text-body">{{ footer.container_step_content }}</span>
      </v-col>

      <DottedArrow :orientation="!mdAndUp ? 'down' : 'right'" />

      <v-col cols="12" md="4" class="d-flex flex-column align-center text-center">
        <v-img
          width="100px"
          max-height="40px"
          class="mb-4"
          :src="IconFooterDot"
          :alt="t('delivery_on_desired_time')" />
        <h4>{{ props.footer.delivery_step_label }}</h4>
        <span>{{ props.footer.delivery_step_content }}</span>
      </v-col>

      <DottedArrow :orientation="!mdAndUp ? 'down' : 'right'" />

      <v-col cols="12" md="3" class="d-flex flex-column align-center text-center pr-md-0">
        <v-img
          width="100px"
          max-height="40px"
          class="mb-4"
          :src="IconFooterSwap"
          :alt="t('smooth_disposal')" />
        <h4>{{ props.footer.pickup_step_label }}</h4>
        <span>{{ props.footer.pickup_step_content }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts" setup>
import IconFooterContainer from '@/assets/icons/footer/footer_container.svg'
import IconFooterDot from '@/assets/icons/footer/footer_dod.svg'
import IconFooterSwap from '@/assets/icons/footer/footer_swap.svg'
import { useDisplay } from 'vuetify'
import type { Footer } from '~/types/types'

const { mdAndUp } = useDisplay()
const { isSafari } = useDevice()
const { t } = useI18n()

const props = defineProps<{
  footer: Footer
}>()
</script>

<style lang="scss" scoped>
h4 {
  font-size: 18px;
  margin-bottom: 16px;
}

.first-footer-part {
  span {
    font-size: 14px;
  }
}
</style>
